import React, { Component } from "react";

export default class EyeTracking extends Component {
  render() {
    return (
      <div className="eyeTracking">
        <section className="templateux-hero" data-scrollax-parent="true">
          <div className="container">
            <div className="row align-items-center justify-content-center intro">
              <div className="col-md-10" data-aos="fade-up">
                <h1>Eye-tracking</h1>
                <a href="#next" className="go-down js-smoothscroll"></a>
              </div>
            </div>
          </div>
        </section>

        <section className="templateux-portfolio-overlap mb-5" id="next">
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="row work-detail">
                  <div className="col-md-6 ">
                    <span className="caption">Role</span>
                    <strong>iOS developer at Lexplore</strong>
                  </div>
                  <div className="col-md-6">
                    <span className="caption">Keywords</span>
                    <strong>
                      iOS Development, Computer Graphics, Projective Geometry
                    </strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="mb-5">
                      <a href="https://www.lexplore.com/sv/ ">Lexplore </a>
                      is a company that offers an innovative tool for reading
                      assessment in schools. Reading is a fundamental skill for
                      learning as it affects outcomes in all subjects, and it is
                      the single most important factor for educational success.
                      By combining eye tracking with machine learning, Lexplore
                      enables accurate assessment of a student’s reading ability
                      in minutes. The results can then be used by teachers for
                      individualized intervention and progress monitoring.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Most commercial eye-trackers rely on dedicated, expensive
                      hardware to track eye movements and run on larger monitors
                      such as desktop displays. Eye-tracking on mobile devices
                      using their built-in camera would be beneficial on a large
                      scale since additional, expensive, equipment would not be.
                      In this project I aimed to implement a mobile device
                      eye-tracker using augmented reality and evaluate the
                      accuracy and precision that system could obtain.
                    </p>
                  </div>
                </div>
                <p className="text-center mb-5">
                  <img
                    src="images/intersection.png"
                    alt="project"
                    className="img-fluid"
                  />
                </p>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <p>
                      The eye-tracker was implemented in Swift using ARKit to
                      track the head and eyes. The methods used for gaze
                      estimation was a ray-plane intersection in which the
                      mobile device was modelled as a 2D plane (see image).
                      Including a system-controlled calibration using linear
                      scaling and translation to adjust the future gaze
                      positions.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      The accuracy and precision were evaluated by two
                      validations using a point pattern to collect gaze data on
                      fixations detected by the system. The conclusions from the
                      study are that mobile device eye-tracking is feasible,
                      easy to implement and delivers reasonable results in terms
                      of accuracy and precision.
                    </p>
                  </div>
                </div>

                <p className="text-center mb-5">
                  <img
                    src="images/eye-tracking-img.png"
                    alt="project"
                    className="img-fluid"
                  />
                </p>
                <div className="row justify-content-center">
                  <p className="text-center" style={{ marginRight: "15px" }}>
                    <a
                      href="https://github.com/moawinberg/eye-tracking"
                      className="button button--red"
                    >
                      View on GitHub
                    </a>
                  </p>
                  <p className="text-center">
                    <a
                      href="https://www.diva-portal.org/smash/record.jsf?dswid=5602&pid=diva2%3A1609277&c=4&searchType=SIMPLE&language=sv&query=moa+winberg&af=%5B%5D&aq=%5B%5B%5D%5D&aq2=%5B%5B%5D%5D&aqe=%5B%5D&noOfRows=50&sortOrder=author_sort_asc&sortOrder2=title_sort_asc&onlyFullText=false&sf=all"
                      className="button button--red"
                    >
                      Link to study
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

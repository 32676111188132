import React, { Component } from "react";

export default class TagAr extends Component {
  render() {
    return (
      <div className="tagAr">
        <section className="templateux-hero" data-scrollax-parent="true">
          <div className="container">
            <div className="row align-items-center justify-content-center intro">
              <div className="col-md-10" data-aos="fade-up">
                <h1>tagAR</h1>
                <a href="#next" className="go-down js-smoothscroll"></a>
              </div>
            </div>
          </div>
        </section>

        <section className="templateux-portfolio-overlap mb-5" id="next">
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="row work-detail">
                  <div className="col-md-6 ">
                    <span className="caption">Role</span>
                    <strong>iOS developer</strong>
                  </div>

                  <div className="col-md-6">
                    <span className="caption">Keywords</span>
                    <strong>Multimodal interaction, Swift, AR</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="mb-5">
                      tagAr is a virtual graffiti application implemented in
                      Swift using ARkit. The goal of the project was to explore
                      how different feedback modalities such as audio, gestures
                      and haptics affected the user experience.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      The position of the spray dot in the augmented reality
                      scene were obtained using a hit test. These coordinates
                      were then transformed into world coordinates. From these a
                      2D plane with an image could then be attached representing
                      the spray dot.
                    </p>
                  </div>
                </div>
                <p className="text-center mb-5">
                  <img
                    src="images/p2.png"
                    alt="project"
                    className="img-fluid"
                  />
                </p>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <p>
                      The conclusion from the study was that the more redundant
                      feedback modalities which occurs in the natural activity
                      is mimicked, the better.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      The project was created in the course DT2140 Multimodal
                      Interaction and Interfaces at KTH - Royal Institute of
                      Technology.
                    </p>
                  </div>
                </div>
                <video
                  style={{ marginBottom: "5vh" }}
                  poster="images/poster.png"
                  className="img-fluid"
                  controls
                >
                  <source src="videos/tagAR.mp4" type="video/mp4" />
                </video>
                <p className="text-center">
                  <a
                    href="https://github.com/moawinberg/tagar"
                    className="button button--red"
                  >
                    View on GitHub
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from 'react'
import Projects from './projects'
import About from "./about";

export default class Home extends Component {
  render() {
    return (
      <div className="home">
        <Projects />
        <About />
      </div>
    )
  }
}
import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div className="about">
        <section className="templateux-section" id="next">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="profile-img"
                  alt="me"
                  src="images/person3.png"
                />
              </div>
              <div className="col-md-6">
                <h2>Hi, I'm Moa.</h2>
                <br />
                <br />
                <p>
                  I am a software engineer based in Stockholm with a MSc in
                  Media Technology, KTH - Royal Institute of Technology.
                  <br />
                  <br />
                  Former employee at{" "}
                  <a href="https://codedesign.se/">Code & Design</a>. Currently
                  working as a consultant at{" "}
                  <a href="https://mpyadigital.com/">Mpya Digital</a>.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="templateux-footer">
        <div className="container-fluid">
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              className="icon-mail"
              style={{ marginRight: "10px", fontSize: "1.2em" }}
            ></span>
            <a style={{ color: "white" }} href="mailto:moa.winberg@icloud.com">
              moa.winberg@icloud.com
            </a>
          </p>

          <div className="text-md text-center footer-social">
            <a href="https://github.com/moawinberg" className="p-3">
              <span className="fa fa-github-alt"></span>
            </a>
            <a href="https://www.linkedin.com/in/moawinberg/" className="p-3">
              <span className="icon-linkedin2"></span>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}
